import { OnValidate, OnMount, OnChange, Monaco } from "@monaco-editor/react";
import { editor } from "monaco-editor";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ReadonlyFeature } from "../utils/monaco-readonly";
import { Session, useSession } from "./session.provider";
import { useUnit } from "./unit.provider";

export const findLineNumber = (editorValue: string, lineValue: string) => {
  const line = editorValue.split("\n").indexOf(lineValue);
  return line && line > -1 ? line + 1 : undefined;
};

type EditorContextValue = Pick<ReturnType<typeof useUnit>, "unit" | "code"> &
  Pick<ReturnType<typeof useSession>, "updateCurrentSession"> & {
    opacity: number;
    loading: boolean;
    session: Session | undefined;
    editorRef: React.RefObject<editor.IStandaloneCodeEditor | undefined>;
    monacoRef: React.RefObject<Monaco | undefined>;
    markers: editor.IMarker[];
    lastValidatedAt: Date | undefined;
    mountedAt: Date | undefined;

    showAnswer: () => void;
    reset: () => void;

    onMount: OnMount;
    onChange: OnChange;
    onValidate: OnValidate;

    setMarkers: React.Dispatch<React.SetStateAction<editor.IMarker[]>>;
    setLastValidatedAt: React.Dispatch<React.SetStateAction<Date | undefined>>;
  };

const EditorContext = createContext<EditorContextValue>(
  null as unknown as EditorContextValue
);

type Props = {
  children: React.ReactNode;
};
export const EditorProvider = ({ children }: Props) => {
  const { unit, code } = useUnit();
  const { getSessionsOfSection, getCurrentSession, updateCurrentSession } =
    useSession();
  const session = getCurrentSession();
  const [opacity, setOpacity] = useState(0);
  const [showingHint, setShowingHint] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastValidatedAt, setLastValidatedAt] = useState<Date>();
  const [mountedAt, setMountedAt] = useState<Date>();

  const [markers, setMarkers] = useState<editor.IMarker[]>([]);
  const editorRef = useRef<editor.IStandaloneCodeEditor>();
  const monacoRef = useRef<Monaco>();
  const typingRef = useRef<HTMLAudioElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setLoading(true);
    setLastValidatedAt(undefined);
    setTimeout(() => {
      setLoading(false);
    }, 50);
  }, [unit]);

  useEffect(() => {
    if (loading) {
      setOpacity(0);
    } else {
      setTimeout(() => {
        setOpacity(100);
      }, 100);
    }
  }, [loading]);

  useEffect(() => {
    setTimeout(() => {
      if (!lastValidatedAt) {
        setLastValidatedAt(new Date());
      }
    }, 1000);
  }, [lastValidatedAt]);

  const onChange: EditorContextValue["onChange"] = (value, event) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      updateCurrentSession({ value });
    }, 500);
  };

  const onValidate: EditorContextValue["onValidate"] = (validationMarkers) => {
    setMarkers(validationMarkers);
    setLastValidatedAt(new Date());
  };

  const onMount: EditorContextValue["onMount"] = (editorInstance, monaco) => {
    setMountedAt(new Date());
    editorRef.current = editorInstance;
    monacoRef.current = monaco;
    editorRef.current.focus();

    monaco.editor.defineTheme("my-dark", {
      base: "vs-dark",
      inherit: true,
      rules: [],
      colors: {},
    });
    monaco.editor.setTheme("my-dark");

    const model = editorInstance.getModel();
    model?.setEOL(monaco.editor.EndOfLineSequence.LF);

    const readonlyFeature = new ReadonlyFeature(editorRef.current);

    const editableDecorations =
      code?.editableRanges.map((range) => ({
        range: new monaco.Range(
          range.start?.line ?? Number.NEGATIVE_INFINITY,
          range.start?.char ?? Number.NEGATIVE_INFINITY,
          range.end?.line ?? Number.NEGATIVE_INFINITY,
          range.end?.char ?? Number.NEGATIVE_INFINITY
        ),
        options: {
          className: "editable-range",
        },
      })) ?? [];

    if (editableDecorations.length) {
      editorInstance.deltaDecorations([], editableDecorations);
    }

    code?.disabledRanges.forEach((range) => {
      readonlyFeature?.exclude(
        range.start?.line ?? Number.NEGATIVE_INFINITY,
        range.start?.char ?? Number.NEGATIVE_INFINITY,
        range.end?.line ?? Number.POSITIVE_INFINITY,
        range.end?.char ?? Number.POSITIVE_INFINITY
      );
    });
    readonlyFeature.enable();

    if (session?.value) {
      setTimeout(() => {
        setEditorValue(session?.value);
      }, 100);
    } else {
      updateCurrentSession({ value: code?.workingCode ?? "" });
    }
  };

  const setEditorValue = (str: string, animate = false) => {
    editorRef?.current?.setValue(str);
    if (animate) {
      animateTyping(str);
    }
  };

  const animateTyping = (str: string) => {
    // let curr = editorRef.current?.getValue();
    let curr = "";
    const charsPerSecond = 100;
    const intervalsPerSecond = 20;
    const charsPerInterval = charsPerSecond / intervalsPerSecond;

    if (typingRef.current) {
      typingRef.current.src = process.env.PUBLIC_URL + "/typing.mp3";
      typingRef.current.currentTime = 1;
      typingRef.current.volume = 0.7;
      typingRef.current.play();
    }
    let i = charsPerInterval;
    const interval = setInterval(() => {
      const next = str?.substring(0, i + 1);
      editorRef.current?.setValue(curr);
      if (i >= str.length + 1) {
        if (typingRef.current) {
          typingRef.current.pause();
        }
        clearInterval(interval);
      }
      i += charsPerInterval;
      curr = next;
    }, 20);
  };

  const showAnswer = () => {
    code?.solutionCode && setEditorValue(code.solutionCode);
  };

  const reset = () => {
    code?.workingCode && setEditorValue(code.workingCode);
  };

  const toggleHint = () => setShowingHint(!showingHint);

  const value: EditorContextValue = {
    unit,
    code,
    session,
    editorRef,
    monacoRef,
    opacity,
    loading,
    markers,
    lastValidatedAt,
    mountedAt,

    updateCurrentSession,
    reset,
    showAnswer,

    onMount,
    onChange,
    onValidate,

    setMarkers,
    setLastValidatedAt,
  };

  return (
    <EditorContext.Provider value={value}>
      <audio ref={typingRef} />
      {children}
    </EditorContext.Provider>
  );
};

export const useEditor = () => useContext(EditorContext);
