import { css, cx } from "@emotion/css";

type Props = {
  opacity: number;
  children: React.ReactNode;
};

export const EditorWrap = ({ opacity, children }: Props) => {
  return (
    <main
      className={cx(
        "no-scroll-bars",
        css`
          transition: all 0.3s;
          opacity: ${opacity};
          flex: 1;
          height: 100vh;
          border-radius: 5px;
          overflow: hidden;
          font-size: 0.8em;
          display: flex;
          flex-direction: column;
        `
      )}
    >
      {children}
    </main>
  );
};
