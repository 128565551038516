import { RouteComponentProps } from "react-router";
import { TypescriptEditor } from "../components/editors/ts-editor";
import { SideBar } from "../components/nav/sidebar-nav";
import { SectionsNav } from "../components/nav/sections-nav";
import {
  NavigationProvder,
  useNavigation,
} from "../providers/navigation.provider";
import { SessionProvider } from "../providers/session.provider";
import { UnitProvider, useUnit } from "../providers/unit.provider";
import { EditorProvider } from "../providers/editor.provider";
import { JavascriptEditor } from "../components/editors/js-editor";
import { GoEditor } from "../components/editors/go-editor";

export const UnitsRoute = (_props: RouteComponentProps) => {
  return (
    <NavigationProvder>
      <SessionProvider>
        <UnitsRouteInner />
      </SessionProvider>
    </NavigationProvder>
  );
};

const UnitsRouteInner = () => {
  const { ecosystem } = useNavigation();
  const currPage = ecosystem?.slug;
  return (
    <>
      <SideBar currPage={currPage ?? ""} />
      <SectionsNav />
      <UnitProvider>
        <EditorProvider>
          <EditorSwitch />
        </EditorProvider>
      </UnitProvider>
    </>
  );
};

const EditorSwitch = () => {
  const { unit } = useUnit();
  switch (unit?.language) {
    case "typescript":
      return <TypescriptEditor />;
    case "javascript":
      return <JavascriptEditor />;
    case "go":
      return <GoEditor />;
  }
  return null;
};
