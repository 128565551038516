import { css, cx } from "@emotion/css";
import { faUserClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  ContextualizedUnit,
  ContextualizedUnitSection,
} from "../../../../shared/unit.types";
import { useSession } from "../../../providers/session.provider";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import { useNavigation } from "../../../providers/navigation.provider";

type Props = {
  unit: ContextualizedUnit;
  section: ContextualizedUnitSection;
};

export const QuizPanel = ({ unit, section }: Props) => {
  const { getCurrentSession, getSessionsOfSection, getUnitSession } =
    useSession();
  const { getPath } = useNavigation();
  const session = getCurrentSession();
  const [lastCalculatedTime, setLastCalculatedTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout>();
  const theme = useTheme();
  const sessions = useMemo(
    () => getSessionsOfSection(unit.section),
    [session, unit, unit?.section]
  );

  const getUnitPath = (index: number) => {
    return getPath(
      section.course.ecosystem.slug,
      section.course.slug,
      section.slug,
      index
    );
  };

  // @todo: figure out a better timing mechanism, to stop counting when all are complete
  // const hasSessionInProgress = useMemo( () => {
  //   return Object.values( sessions ).some( session => session.started_at && !session.completed_at);
  // }, [sessions]);

  const hasSessionInProgress = true;

  const totalSectionTime = useMemo(() => {
    const startedAts = Object.values(sessions)
      .filter((session) => !!session.started_at)
      //@todo: improve this. remove the intro lesson (unit 0) of a quiz from time calculations
      .filter((session) => {
        const { length, [length - 1]: last } = session.id.split("/");
        return last !== "0";
      })
      .map((session) => session.started_at?.valueOf() as number);

    const earliestStartedAt = Math.min(...startedAts);
    const totalTime = Date.now() - earliestStartedAt;
    return totalTime;
    // return unit?.section
    //   ? Object.values( sessions ).reduce( (acc, session) => {
    //       const time = session.completed_at && session.started_at
    //         ? session.completed_at.valueOf() - session.started_at.valueOf()
    //         : session.started_at && !session.completed_at
    //           ? Date.now() - session.started_at.valueOf()
    //             : 0;
    //       return acc + time
    //     }, 0)
    //   : 0
  }, [sessions, hasSessionInProgress]);

  useEffect(() => {
    setLastCalculatedTime(Date.now());
  }, [totalSectionTime]);

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      if (hasSessionInProgress) {
        setTotalTime(totalSectionTime + (Date.now() - lastCalculatedTime));
      }
    }, 1000);
  }, [lastCalculatedTime, totalSectionTime, hasSessionInProgress]);

  return (
    <div
      className={css`
        ul {
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: space-between;
          margin-top: 5px;
        }
        li {
          list-style-type: none;
          text-align: center;
          width: 100%;
          margin: 2px;
          &.completed {
            a {
              background: ${theme.colors.success50};
            }
          }
          &.in_progress {
            a {
              background: ${theme.colors.warning50};
            }
          }
          &.active {
            a {
              border: 1px solid ${theme.colors.grey0};
            }
          }
        }
        a {
          color: ${theme.colors.grey0};
          text-decoration: none;
          background: ${theme.colors.grey80};
          font-size: 0.8em;
          padding: 3px;
          display: block;
          border-radius: 3px;
          width: 100%;
          &:hover {
            outline: 1px solid ${theme.colors.grey95};
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
          }
        }
      `}
    >
      <FontAwesomeIcon icon={faUserClock} />{" "}
      {totalTime > 0 && <TimeDisplay time={totalTime} />}
      <br />
      {section.name}
      <ul>
        {section.units.map((u, i) => {
          if (i === 0) {
            return null;
          }
          const session = getUnitSession(u);
          return (
            <li
              className={cx({
                active: u === unit,
                completed: !!session?.completed_at,
                in_progress: session?.started_at && !session?.completed_at,
              })}
            >
              <Link to={getUnitPath(i)}>{i}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const TimeDisplay = ({ time }: { time: number }) => {
  const ms = time & 1000;
  const s = Math.floor(time / 1000) % 60;
  const m = Math.floor(time / (60 * 1000)) % 60;
  const h = Math.floor(time / (60 * 60 * 1000)) % 60;
  return (
    <>
      {h ? <>{h.toString().padStart(2, "0")}:</> : null}
      {m.toString().padStart(2, "0")}:{s.toString().padStart(2, "0")}
    </>
  );
};
