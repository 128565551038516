import { useAuth0 } from "@auth0/auth0-react";
import { css } from "@emotion/css";
import { useTheme } from "@emotion/react";
import { RouteComponentProps } from "react-router";
import { SecondaryButton } from "../components/button/secondary-button";
import { SideBar } from "../components/nav/sidebar-nav";
import { useStyleInjector } from "../hooks/useStyleInjector";

export const UserRoute = (_props: RouteComponentProps) => {
  const { isAuthenticated } = useAuth0();
  const theme = useTheme();

  useStyleInjector`
  body {
      background: linear-gradient(270deg, #000000, #484d4c);
      background-size: 400% 400%;

      -webkit-animation: GradientAnimation 15s ease infinite;
      -moz-animation: GradientAnimation 15s ease infinite;
      animation: GradientAnimation 15s ease infinite;
  }

  @-webkit-keyframes GradientAnimation {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @-moz-keyframes GradientAnimation {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @keyframes GradientAnimation {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }`;
  return (
    <>
      <SideBar
        currPage="user"
        className={css`
          background: transparent;
        `}
      />
      <main
        className={css`
          width: 100%;
          padding: 20px;
          padding-top: 0;
        `}
      >
        <article
          className={css`
            font-family: charter, Georgia, Cambria, "Times New Roman", Times,
              serif;
            max-width: 400px;
            padding: 0px 15px;
            color: ${theme.colors.grey100};
            button {
              cursor: pointer;
            }
          `}
        >
          {isAuthenticated ? <LoggedInView /> : <LoggedOutView />}
        </article>
      </main>
    </>
  );
};

const LoggedInView = () => {
  const { user, logout } = useAuth0();
  return (
    <>
      <h1>Hi {user?.given_name}</h1>
      <p>Sorry, this doesn't do anything yet. </p>
      {/* <p>We keep track of your progress here. Here's what you've done so far!</p>
    <table className={css`
      margin-bottom:20px;
      td {
        text-align:left;
      }
      th, tr {
        border-bottom:1px solid #444;
      }
    `}>
      <thead>
        <th>
          <td>Course</td>
        </th>
      </thead>
      <tbody>
        <tr>
          <td>Typescript</td>
        </tr>
      </tbody>
    </table> */}
      <SecondaryButton
        onClick={() => logout({ returnTo: window.location.origin })}
      >
        Log Out
      </SecondaryButton>
    </>
  );
};

const LoggedOutView = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <>
      <h2>Log in to track your progress</h2>
      <SecondaryButton onClick={() => loginWithRedirect()}>
        Log In
      </SecondaryButton>
    </>
  );
};
