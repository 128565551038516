import { createContext, useContext } from "react";
import { useMemo } from "react";
import { useNavigation } from "./navigation.provider";
import { ContextualizedUnit } from "../../shared/unit.types";

export type Assertion = {
  lineNumber: number;
  lineContent: string;
};

type UnitContextValue = {
  unit: ContextualizedUnit | undefined;
  code:
    | {
        disabledRanges: Range[];
        editableRanges: Range[];
        workingCode: string | undefined;
        solutionCode: string | undefined;
        // twoSlashReport: TwoSlashReturn | undefined,
        // expectedTypeErrors: ExpectedTypeError[],
        // assertions: Assertion[],
      }
    | undefined;
};

type Range = {
  start: { line: number; char: number } | undefined;
  end: { line: number; char: number } | undefined;
};

const UnitContext = createContext<UnitContextValue>({} as UnitContextValue);

/** Finds the indexes of the delimiters, and removes the matching delimiter as it goes */
const getDelimiterIndexes = (source: string) => {
  const seq1 = "<<<";
  const seq2 = ">>>";
  const results = {
    seq1: [] as number[],
    seq2: [] as number[],
  };
  source.split("").forEach((char, i, arr) => {
    const isMatchSeq1 = arr.slice(i, i + seq1.length).join("") === seq1;
    if (isMatchSeq1) {
      arr.splice(i, seq1.length);
      return results.seq1.push(i);
    }
    const isMatchSeq2 = arr.slice(i, i + seq2.length).join("") === seq2;
    if (isMatchSeq2) {
      arr.splice(i, seq2.length);
      return results.seq2.push(i);
    }
  });
  return results;
};

const getTextRanges = (text: string) =>
  text.split("\n").reduce<Range[]>((acc, line, i) => {
    const lineNumber = i + 1;
    const newRanges: Range[] = [];
    let lastRange = acc.length ? acc[acc.length - 1] : undefined;
    let { seq1: openers, seq2: closers } = getDelimiterIndexes(line);
    const closerComesFirst =
      closers[0] !== undefined && (!openers.length || openers[0] > closers[0]);

    if (closerComesFirst) {
      if (!lastRange) {
        lastRange = {
          start: undefined,
          end: { line: lineNumber, char: closers[0] },
        };
        acc[0] = lastRange;
      } else {
        lastRange.end = { line: lineNumber, char: closers[0] };
      }
      closers.shift();
    }

    openers.forEach((opener, j) => {
      const columnNumber = opener + 1;
      const matchingEndIdx = closers.findIndex((closer) => closer > opener);
      const matchingEnd =
        matchingEndIdx !== -1 ? closers[matchingEndIdx] : undefined;
      if (matchingEnd) {
        //remove any closers on this line that come before this opener
        closers = closers.slice(matchingEndIdx);
      } else {
        //remove any other closers on this line (because none of them come after this opener)
        closers = closers.filter((closer) => false);
      }

      const newRange: Range = {
        start: { line: lineNumber, char: columnNumber },
        end: matchingEnd ? { line: lineNumber, char: matchingEnd } : undefined,
      };
      newRanges.push(newRange);
    });

    return [...acc, ...newRanges];
  }, []);

type Props = {
  children: React.ReactNode;
};
export const UnitProvider = ({ children }: Props) => {
  const { unit } = useNavigation();

  const [disabledRanges, editableRanges] = useMemo(() => {
    const disabled = unit?.code
      ? getTextRanges(unit?.code.replace(/(\[\[\[.+?\]\]\])/gs, ""))
      : [];

    //basically gets the portions between the disabled ranges
    const editable = disabled.reduce<Range[]>((acc, range, i, arr) => {
      const next = arr[i + 1];
      if (!next && range.end) {
        return [
          ...acc,
          {
            start: { line: range.end.line, char: range.end.char },
            end: undefined,
          },
        ];
      } else if (range.end) {
        return [
          ...acc,
          {
            start: { line: range.end.line, char: range.end.char },
            end:
              next && next.start
                ? { line: next.start.line, char: next.start.char }
                : undefined,
          },
        ];
      }
      return acc;
    }, []);
    return [disabled, editable];
  }, [unit]);

  //Note, I have to do this after I've obtained the textRanges, because they rely on the delimiters
  const workingCode = useMemo(
    () =>
      unit?.code
        .replaceAll("<<<", "")
        .replaceAll(">>>", "")
        .replace(/(\[\[\[.+?\]\]\])/gs, ""),
    [unit]
  );

  const solutionCode = useMemo(
    () =>
      unit?.code
        .replaceAll("<<<", "")
        .replaceAll(">>>", "")
        .replaceAll(/\[\[\[/g, "")
        .replaceAll(/\]\]\]/g, ""),
    [unit]
  );

  const value: UnitContextValue = {
    unit,
    code: {
      disabledRanges,
      editableRanges,
      workingCode,
      solutionCode,
    },
  };

  return <UnitContext.Provider value={value}>{children}</UnitContext.Provider>;
};

export const useUnit = () => useContext(UnitContext);
