import React, { useEffect, useRef, useState } from "react";
import { css, cx } from "@emotion/css";
import { Link } from "react-router-dom";
import { useSession } from "../../providers/session.provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronDown,
  faChevronRight,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigation } from "../../providers/navigation.provider";
import { Languages } from "../../../shared/unit.types";
import { useClickOutside } from "../../hooks/use-click-outside";
import { Theme } from "../../providers/ui.provider";
import { useTheme } from "@emotion/react";

const navigationCss = (theme: Theme) => css`
  margin: 0;
  padding: 0;
  width: 250px;
  ul {
    margin: 0;
    padding: 0;
    width: 250px;
  }
  li {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  a,
  header {
    display: block;
    cursor: pointer;
    &:hover:not(.active) {
      background-color: ${theme.colors.grey15};
    }
    &.active {
      background-color: ${theme.colors.grey30};
    }
    padding: 5px 20px;
    text-decoration: none;
    color: white;
    &.completed {
      color: ${theme.colors.success60};
    }
    &.in_progress {
      color: ${theme.colors.warning50};
    }
    svg {
      margin-right: 5px;
    }
  }
  a:not(.assessment) {
    padding-left: 30px;
  }
`;

const collapsedNavCss = (theme: Theme) => css`
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    cursor: pointer;
    text-align: center;
    list-style-type: none;
    border-radius: 4px;
    padding: 2px;
    font-weight: bold;
    &.collapsed-header {
      &.active {
        span {
          background: ${theme.colors.grey0};
        }
      }
      span {
        transition: all 0.3s;
        display: inline-block;
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        border: 1px solid ${theme.colors.grey0};
        border-radius: 5px;
        background: ${theme.colors.grey5};
        font-size: 1.5em;
        text-align: center;
        &:hover:not(.active) {
          background: ${theme.colors.grey0};
        }
      }
    }
    &.collapsed-unit a {
      color: white;
      display: inline-block;
      text-decoration: none;
      transition: 0.3s;
      font-size: 0.7em;
      width: 16px;
      height: 16px;
      box-sizing: border-box;
      padding: 2px 3px;
      /* border: 1px solid ${theme.colors.grey0}; */
      border-radius: 5px;
      /* background: ${theme.colors.grey5}; */
      &:hover:not(.active) {
        background: ${theme.colors.grey0};
      }
      &.in_progress {
        border: 1px solid ${theme.colors.warning50};
        color: ${theme.colors.warning50};
      }
      &.completed {
        border: 1px solid ${theme.colors.success60};
        color: ${theme.colors.success60};
      }
      &.active {
        color: ${theme.colors.grey100};
        &.in_progress {
          background: ${theme.colors.warning50};
        }
        &.completed {
          background: ${theme.colors.success60};
        }
        font-weight: bold;
      }
    }
  }
`;

type Props = {
  collapsed?: boolean;
};

export const SectionsNav = ({ collapsed: defaultCollapsed }: Props) => {
  const {
    section: currSection,
    course,
    ecosystem,
    unit: currUnit,
    getPath,
  } = useNavigation();
  const { constructId, getSession } = useSession();
  const [openSections, setOpenSections] = useState<string[]>([]);
  const [collapsed, setCollapsed] = useState(true);
  const wrapperRef = useRef<HTMLElement>(null);
  const theme = useTheme();
  useClickOutside(wrapperRef, () => {
    setCollapsed(true);
  });

  useEffect(() => {
    setCollapsed(!!defaultCollapsed);
  }, [defaultCollapsed]);

  useEffect(() => {
    if (currSection?.slug && !openSections.includes(currSection.slug)) {
      setOpenSections([...openSections, currSection.slug]);
    }
  }, [currSection?.slug]);

  const toggleSection = (section: string) => {
    const index = openSections.indexOf(section);
    const next = [...openSections];
    index > -1 ? next.splice(index, 1) : next.push(section);
    return setOpenSections(next);
  };

  if (!ecosystem || !course) {
    return null;
  }

  return (
    <nav
      ref={wrapperRef}
      onClick={() => setCollapsed(false)}
      className={cx(
        "no-scroll-bars",
        css`
          transition: flex-basis 0.3s;
          flex: 0 0 ${collapsed ? "50px" : "250px"};
          border-right: 1px solid ${theme.colors.grey2};
          font-size: 0.8em;
          height: 100vh;
          overflow: scroll;
        `
      )}
    >
      <div
        className={css`
          padding-top: 5px;
          text-align: ${collapsed ? "center" : "right"};
        `}
      >
        <button
          onClick={(e) => {
            e.stopPropagation();
            setCollapsed(!collapsed);
          }}
          className={css`
            border: none;
            cursor: pointer;
            border-radius: 3px;
            color: ${theme.colors.grey100};
            padding: ${collapsed ? "6px 5px 3px 7px" : "6px 7px 3px 5px"};
            background: transparent;
            :hover {
              background: ${theme.colors.grey35};
            }
          `}
        >
          {collapsed ? (
            <FontAwesomeIcon icon={faAngleDoubleRight} />
          ) : (
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          )}
        </button>
      </div>

      {collapsed && (
        <ul className={collapsedNavCss(theme)}>
          {course.sections.map((section) => {
            const isOpen = openSections.includes(section.slug);
            return (
              <React.Fragment key={section.slug}>
                <li
                  onClick={() => toggleSection(section.slug)}
                  className={cx("collapsed-header", {
                    active: currSection === section,
                  })}
                >
                  <span>
                    {" "}
                    {section.type === "assessment" ? (
                      <FontAwesomeIcon icon={faClock} size="xs" />
                    ) : (
                      section.name[0]
                    )}
                  </span>
                </li>
                {section.type !== "assessment" &&
                  isOpen &&
                  section.units.map((unit, i) => {
                    const session = getSession(
                      constructId(
                        ecosystem.slug,
                        course.slug,
                        section.slug,
                        unit.id
                      )
                    );
                    return (
                      <li key={unit.id} className="collapsed-unit">
                        <Link
                          className={cx({
                            active:
                              section.slug === currSection?.slug &&
                              currUnit?.id === unit.id,
                            completed: session && !!session.completed_at,
                            in_progress: session && !session.completed_at,
                          })}
                          to={getPath(
                            ecosystem.slug,
                            course.slug,
                            section.slug,
                            unit.id
                          )}
                        >
                          {section.type === "assessment" ? (
                            <FontAwesomeIcon icon={faClock} size="xs" />
                          ) : (
                            i + 1
                          )}
                        </Link>
                      </li>
                    );
                  })}
              </React.Fragment>
            );
          })}
        </ul>
      )}

      {!collapsed && (
        <>
          <header
            className={css`
              padding: 10px;
              width: 250px;
              svg {
                margin-right: 10px;
              }
            `}
          >
            <FontAwesomeIcon size="xs" icon={faChevronDown} />
            {course?.name}
          </header>
          <ul className={navigationCss(theme)}>
            {course.sections.map((section) => {
              const isOpen = openSections.includes(section.slug);
              if (section.type === "assessment") {
                return (
                  <Link
                    className={cx({
                      assessment: true,
                      // active: section.slug === currSection?.slug && unitIndex === i,
                      // completed: session && !!session.completed_at,
                      // in_progress: session && !session.completed_at
                    })}
                    to={getPath(
                      ecosystem.slug,
                      course.slug,
                      section.slug,
                      section.units[0].id
                    )}
                  >
                    <FontAwesomeIcon size="xs" icon={faClock} /> {section.name}
                  </Link>
                );
              }
              return (
                <li key={section.slug}>
                  <header onClick={() => toggleSection(section.slug)}>
                    <FontAwesomeIcon
                      size="xs"
                      icon={isOpen ? faChevronDown : faChevronRight}
                    />{" "}
                    {section.name}
                  </header>
                  {isOpen && (
                    <ul>
                      {section.units.map((unit, i) => {
                        const session = getSession(
                          constructId(
                            ecosystem.slug,
                            course.slug,
                            section.slug,
                            unit.id
                          )
                        );
                        return (
                          <li key={unit.id}>
                            <Link
                              className={cx({
                                active:
                                  section.slug === currSection?.slug &&
                                  currUnit?.id === unit.id,
                                completed: session && !!session.completed_at,
                                in_progress: session && !session.completed_at,
                              })}
                              to={getPath(
                                ecosystem.slug,
                                course.slug,
                                section.slug,
                                i
                              )}
                            >
                              {
                                section.type === "tutorial" ? (
                                  <FileIconSwitch language={unit.language} />
                                ) : (
                                  <FontAwesomeIcon icon={faClock} size="xs" />
                                ) //@todo: unused
                              }
                              {unit.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </nav>
  );
};

const TSIcon = (theme: Theme) => css`
  color: ${theme.colors.tsBlueAlt};
  font-weight: bold;
  margin-right: 7px;
  font-size: 0.9em;
`;

const JSIcon = (theme: Theme) => css`
  color: ${theme.colors.jsYellow};
  font-weight: bold;
  margin-right: 7px;
  font-size: 0.9em;
`;

const GoIcon = css`
  width: 20px;
  margin-right: 7px;
`;

type FileIconSwitchProps = {
  language: Languages;
};
const FileIconSwitch = ({ language }: FileIconSwitchProps) => {
  const theme = useTheme();
  switch (language) {
    case "typescript":
      return <span className={TSIcon(theme)}>TS</span>;
    case "javascript":
      return <span className={JSIcon(theme)}>JS</span>;
    case "go":
      return <img src="/go-logo.png" className={GoIcon} alt="Go Icon" />;
  }
  return null;
};
