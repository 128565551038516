import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";
import { ContentProvider } from "./providers/content.provider";
import { UnitsRoute } from "./routes/units.route";
import { UserRoute } from "./routes/user.route";
import { InfoRoute } from "./routes/info.route";
import { UIProvider } from "./providers/ui.provider";

function App() {
  return (
    <>
      <Analytics />
      <Auth0Provider
        domain="gish.us.auth0.com"
        clientId="BeCtn6yQFY11DgTwS7qWefuFZqJK5h1v"
        redirectUri={window.location.origin + "/user"}
      >
        <ContentProvider>
          <BrowserRouter>
            <UIProvider>
              <Switch>
                <Route {...{ path: "/", exact: true, component: UnitsRoute }} />
                <Route {...{ path: "/user", component: UserRoute }} />
                <Route {...{ path: "/info", component: InfoRoute }} />
                <Route {...{ path: "*", component: UnitsRoute }} />
              </Switch>
            </UIProvider>
          </BrowserRouter>
        </ContentProvider>
      </Auth0Provider>
    </>
  );
}

export default App;
