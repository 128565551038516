import React, { useEffect, useMemo, useRef, useState }  from 'react';
import Editor from '@monaco-editor/react';
import axios from 'axios'
import { RunResponseError, RunResponseDTO } from '../../../shared/api.dto'
import { FeedbackItem, Console } from './panels/console';
import { useEditor } from '../../providers/editor.provider';
import { EditorWrap } from './editor-wrap';
import { editorCSS } from './editor.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircleNotch, faHourglassStart, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Assertion } from '../../providers/unit.provider'
import { useTheme } from '@emotion/react';

type GoResponseEvent = {
  Delay: number,
  Kind: 'stdout',
  Message: 'string',
}

type GoResponse = {
  Errors: string;
  Events: GoResponseEvent[];
  IsTest: boolean;
  Status: number;
  TestsFailed: number;
}

export const isFailedRunResponse = (res: RunResponseDTO) => {
  return !!res.errors?.length
}

const parseRunErrors = (errs: string) => {
  return errs.split("\n").filter( err => !!err).map<RunResponseError>( err => {
    const [fileName, lineNumber, columnNumber, ...message] = err.split(":");
    return {
      line: +lineNumber,
      code: '',
      message: message.join(' '),
      trace: '',
    }
  })
}

const parseAssertionLine = (line: string) => {
  return line.replace("test( i => i.", "")
    .replaceAll(".", " ")
    .replaceAll("(", " ")
    .replaceAll(")", " ")
    .replaceAll(";", "");
}


export const GoEditor = () => {
  const { 
    unit,
    code,
    session,
    updateCurrentSession,
    opacity,
    loading, 
    editorRef,
    monacoRef,
    reset,
    showAnswer,
    markers,

    onMount,
    onChange,
    onValidate,

  } = useEditor();

  const [running, setRunning] = useState(false)
  const [lastRunResponse, setLastRunResponse] = useState<RunResponseDTO>();
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const theme = useTheme();
  const assertionDecorationsRef = useRef<string[]>([]);

  const run = () => {
    if(!unit){
      return;
    }
    if(running){
      return;
    }
    if(markers.length){
      return;
    }
    setRunning(true);
    const requestData = new FormData();
    requestData.append('version', '2');
    requestData.append('withVet', 'true');
    requestData.append('body', editorRef?.current?.getValue() ?? '')

    const res = axios.request<GoResponse>({
      url: 'https://play.golang.org/compile',
      method: 'POST',
      data: requestData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then( res => {
      if(res.data.Errors){
        return setLastRunResponse({
          errors: parseRunErrors( res.data.Errors )
        })
      } else {
        setLastRunResponse({
          result: {
            failedAssertions: [],
            response: res.data.Events.map( event => event.Message).join('\n\n'),
          }
        })
      }
    }).catch( err => {
      console.log(err)
      setLastRunResponse({errors: [{ 
        message: err.message,
        trace: '',
        code: '',
        line: 0,
      }]})
    }).finally( () => {
      setRunning(false);
    })

    // axios.post('/api/run-go', {
    //   language: unit?.language, 
    //   code: editorRef.current?.getValue(),
    // } ).then( res => {
    //   console.log({res})
    //   setLastRunResponse(res.data);
    // } )
    // .finally( () => {
    //   setRunning(false);
    // })
  }

  const onMountWrap: typeof onMount = (editor, monaco) => {
    onMount(editor, monaco)
  }

  const onChangeWrap: typeof onChange = (value, ev) => {
    if(timeoutRef.current){
      clearTimeout( timeoutRef.current )
    }
    timeoutRef.current = setTimeout( () => {
      run();
    }, 500);
    onChange(value, ev);
  }

  const assertions = useMemo( () => {
    return session?.value.split( "\n" ).reduce<Assertion[]>( (acc, line, i) => {
      return /expect\(.*\)/g.test(line)
        ? [...acc, {lineNumber: i+1, lineContent: line}]
        : acc;
    }, []) ?? []
    
  }, [session?.value])

  useEffect( () => {
    if(!monacoRef?.current){
      return;
    }
    
    const hasRun = !!lastRunResponse;
    const lastRunErrors = lastRunResponse?.errors ?? [];
    const failedAssertions = lastRunResponse?.result?.failedAssertions ?? [];

    const monaco = monacoRef?.current;
    const decorations = assertions.map( error => {
      let className;
      const match = failedAssertions?.find( a => a.startLineNumber === error.lineNumber);
      if( !hasRun ){
        className = 'line-icon line-circle-dotted warning';
      } else if( hasRun && !lastRunErrors?.length && !match ) {
        className = 'line-icon line-check success';
      } else {
        className = 'line-icon line-times error';
      }
      return {
        range: new monaco.Range(
          error.lineNumber, Number.NEGATIVE_INFINITY,
          error.lineNumber as number, Number.POSITIVE_INFINITY
        ), options: { 
          isWholeLine: true, 
          linesDecorationsClassName: className
        },
      }})


    //remove old decorations
    const prevDecorations = editorRef?.current?.deltaDecorations(assertionDecorationsRef.current ?? [], []);
    //assign new decorations, and assign them to ref
    assertionDecorationsRef.current = editorRef?.current?.deltaDecorations(prevDecorations ?? [], decorations) ?? [];
  }, [assertions, lastRunResponse])


  const isPassing = !!(
    !!session?.value 
    && !loading
    && lastRunResponse
    && !isFailedRunResponse(lastRunResponse)
    && !lastRunResponse?.result?.failedAssertions?.length
    && !markers.length
  );

  useEffect( () => {
    updateCurrentSession({
      completed_at: isPassing ? new Date() : undefined,
    })
  }, [isPassing])


  const feedbackItems: FeedbackItem[] = useMemo( () => {
    console.log({markers, lastRunResponse})
    if(isPassing){
      return [];
    }
    const hasRun = !!lastRunResponse;
    const lastRunErrors = lastRunResponse?.errors ?? [];
    const failedAssertions = lastRunResponse?.result?.failedAssertions ?? [];

    if(markers.length){
      return markers.map<FeedbackItem>( err => ({
        lineNumber: err.startLineNumber,
        columnNumber: err.startColumn,
        icon: faTimesCircle,
        message: err.message,
        status: 'error',
      }))
    } else if(running){
      return [{
        message: "Code is running...",
        icon: faHourglassStart,
        status: 'warning',
      }]
    }
    if(!lastRunResponse){
      return [{
        message: "Make some changes to run your code",
        icon: faTimesCircle,
        status: 'warning',
      }]
    } else if(lastRunResponse && lastRunErrors?.length){
      return lastRunErrors.map( err => ({
        message: <><strong>Error</strong> {err.message}</>,
        icon: faTimesCircle,
        status: 'error',
        lineNumber: err.line === 0 ? undefined : err.line
      }))
    } else {
      return assertions.map<FeedbackItem>( assertion => {
        const match = failedAssertions.find( a => a.startLineNumber === assertion.lineNumber)
        return {
          icon: match ? faTimesCircle : faCheckCircle,
          status: match ? 'error' : 'success',
          lineNumber: assertion.lineNumber,
          message: match 
            ? <><strong>Failed Assertion</strong> Make sure the expected value is received</>
            : <><strong>Assertion Passed!</strong> {parseAssertionLine( assertion.lineContent )}</>,
        }
      })
    }
  }, [markers, assertions, lastRunResponse, running]);

  if(loading){
    return null
  }

  return <EditorWrap opacity={opacity}>
      <button disabled={running} onClick={run}>
        { running ? <FontAwesomeIcon spin={true} icon={faCircleNotch}/> : "Run" }
      </button>
      <button onClick={reset}>Reset</button>
      <button onClick={showAnswer}>Show Answer</button>
    <Editor {...{
      className: editorCSS(theme),
      height: '60%',
      defaultLanguage: unit?.language === "javascript" ? "typescript" : unit?.language,
      defaultValue: code?.workingCode,
      theme:  "my-dark",
      onMount: onMountWrap,
      onChange: onChangeWrap,
      onValidate: onValidate,
      options: {
        fontSize: 14,
        readOnly: false,
        minimap: {
          enabled: false,
        },
      }
    }}
    />
    <Console 
      {...{isPassing, feedbackItems, output: lastRunResponse?.result?.response ?? null}}
    />
  </EditorWrap>
}