import { css } from "@emotion/css";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigation } from "../../../providers/navigation.provider";

export const Breadcrumbs = () => {
  const {ecosystem, course, section, unit} = useNavigation();
  
  return <div className={css`font-size:.8em; font-style: italic;`}>
   {/* {ecosystem?.name}
   <FontAwesomeIcon icon={faChevronRight} />
   {course?.name}
   <FontAwesomeIcon icon={faChevronRight} /> */}
   {section?.name}
   <FontAwesomeIcon size="xs" icon={faChevronRight} className={css`margin: 0px 10px;`}/>
   {unit?.name}
 </div>
}