import React from "react"
import {useTheme} from '@emotion/react'
import { css, cx } from "@emotion/css";

export const SecondaryButton = React.forwardRef<
  HTMLButtonElement, React.ComponentProps<'button'>>
(({children, ...props}, ref) => {
  const theme = useTheme();
  return <button {...props}
    ref={ref}
    className={cx(css`
      color: ${theme.colors.grey20};
      border-radius: 3px;
      padding: 5px 10px;
      border: none;
      cursor: pointer;
      margin-right: 10px;
      margin-bottom: 10px;
      border: 1px solid ${theme.colors.grey50};
      background: ${theme.colors.grey95};
      :hover { 
        background: ${theme.colors.grey80}
      }
    `,props.className)
  }>
    {children}
  </button>
})