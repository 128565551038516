import { createContext, useContext, useState } from "react";
import {
  ContextualizedUnit,
  ContextualizedUnitCourse,
  ContextualizedUnitSection,
} from "../../shared/unit.types";
import { useNavigation } from "./navigation.provider";

type Help = { type: "hint" | "answer"; at: Date | undefined };

/**
 * Each unit can have a session in the user's sessions
 * @param id: a forward slash separated string with `ecosystem/course/section/unit`
 */
export type Session = {
  id: string;
  value: string;
  help: Help[];
  started_at: Date | undefined;
  completed_at: Date | undefined;
};

export type SessionContextValue = {
  sessions: Record<string, Session>;
  constructId: (
    ecosystem: string,
    course: string,
    section: string,
    unit: number
  ) => string;
  getSession: (id: string) => Session | undefined;
  updateSession: (
    id: Session["id"],
    session: Partial<Omit<Session, "id">>
  ) => void;
  removeSession: (id: Session["id"]) => void;
  getCurrentSession: () => Session | undefined;
  updateCurrentSession: (session: Partial<Omit<Session, "id">>) => void;
  removeCurrentSession: () => void;
  getSessionsOfSection: (
    section: ContextualizedUnitSection
  ) => Record<string, Session>;
  getSessionsOfCourse: (
    course: ContextualizedUnitCourse
  ) => Record<string, Session>;
  getUnitSession: (unit: ContextualizedUnit) => Session | undefined;
  attachSessions: (
    units: ContextualizedUnit[]
  ) => (ContextualizedUnit & { session: Session | undefined })[];
};

const sessionContext = createContext<SessionContextValue>(
  null as unknown as SessionContextValue
);

type Props = {
  children: React.ReactNode;
};
export const SessionProvider = ({ children }: Props) => {
  const [sessions, setSessions] = useState<SessionContextValue["sessions"]>({});
  const { unit, ecosystem, course, section } = useNavigation();

  const constructId: SessionContextValue["constructId"] = (
    ecosystemSlug: string,
    courseSlug: string,
    sectionSlug: string,
    unitId: number
  ) => [ecosystemSlug, courseSlug, sectionSlug, unitId].join("/");

  const getSession: SessionContextValue["getSession"] = (id) => sessions[id];

  const getUnitSession: SessionContextValue["getUnitSession"] = (unit) => {
    const id = constructId(
      unit.section.course.ecosystem.slug,
      unit.section.course.slug,
      unit.section.slug,
      unit.id
    );
    return getSession(id);
  };

  const getSessionsOfSection: SessionContextValue["getSessionsOfSection"] = (
    section
  ) => {
    const course = section.course;
    const ecosystem = course.ecosystem;
    return section.units.reduce<Record<string, Session>>((acc, unit) => {
      const key = constructId(
        ecosystem.slug,
        course.slug,
        section.slug,
        unit.id
      );
      const session = getSession(key);
      return {
        ...acc,
        ...(session && { [key]: session }),
      };
    }, {});
  };

  const getSessionsOfCourse: SessionContextValue["getSessionsOfCourse"] = (
    course
  ) => {
    return course.sections.reduce<Record<string, Session>>(
      (acc, section, i) => {
        return {
          ...acc,
          ...getSessionsOfSection(section),
        };
      },
      {}
    );
  };

  const attachSessions: SessionContextValue["attachSessions"] = (units) => {
    return units.map((unit) => {
      return {
        ...unit,
        session: getUnitSession(unit),
      };
    });
  };

  const updateSession: SessionContextValue["updateSession"] = (id, session) => {
    setSessions({
      ...sessions,
      [id]: {
        id,
        value: ("value" in session ? session.value : sessions[id]?.value) ?? "",
        help: ("help" in session ? session.help : sessions[id]?.help) ?? [],
        started_at:
          ("started_at" in session
            ? session.started_at
            : sessions[id]?.started_at) ?? new Date(),
        completed_at:
          ("completed_at" in session
            ? session.completed_at
            : sessions[id]?.completed_at) ?? undefined,
      },
    });
  };

  const removeSession: SessionContextValue["removeSession"] = (id) => {
    const { [id]: remove, ...remaining } = sessions;
    setSessions(remaining);
  };

  const id =
    ecosystem && course && section && typeof unit?.id !== "undefined"
      ? constructId(ecosystem?.slug, course?.slug, section?.slug, unit.id)
      : undefined;

  const getCurrentSession = () => (id ? sessions[id] : undefined);

  const updateCurrentSession = (session: Partial<Omit<Session, "id">>) =>
    id ? updateSession(id, session) : undefined;

  const removeCurrentSession = () => (id ? removeSession(id) : undefined);

  const value: SessionContextValue = {
    sessions,
    constructId,
    getSession,
    updateSession,
    removeSession,
    getCurrentSession,
    updateCurrentSession,
    removeCurrentSession,
    getSessionsOfCourse,
    getSessionsOfSection,
    getUnitSession,
    attachSessions,
  };

  return (
    <sessionContext.Provider value={value}>{children}</sessionContext.Provider>
  );
};

export const useSession = () => {
  const value = useContext(sessionContext);
  if (!value) {
    throw new Error(
      "Session Context can only be used when wrapped in a provider"
    );
  }
  return value;
};
