import { useState } from "react";
import { css, cx } from "@emotion/css";
import { useTheme } from "@emotion/react";
import {
  faCheck,
  faCheckCircle,
  faChevronRight,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigation } from "../../../providers/navigation.provider";
import { Theme } from "../../../providers/ui.provider";
import { PrimaryButton } from "../../button/primary-button";

export type FeedbackItem = {
  lineNumber?: number | undefined;
  columnNumber?: number | undefined;
  icon: IconDefinition;
  message: React.ReactNode;
  status: "success" | "error" | "warning";
  errorCode?: string | undefined;
};

type Props = {
  isPassing: boolean;
  feedbackItems: FeedbackItem[];
  output?: React.ReactNode;
};

const colors = (theme: Theme) => ({
  error: theme.colors.error50,
  success: theme.colors.success50,
  warning: theme.colors.warning50,
});

export const Console = ({ isPassing, feedbackItems, output }: Props) => {
  const theme = useTheme();
  const { goToNextUnit } = useNavigation();
  const [currTab, setCurrTab] = useState("feedback");
  const hasOutputTab = typeof output !== "undefined";
  return (
    <section
      className={cx(
        "no-scroll-bars",
        css`
          height: 40%;
          overflow: scroll;
          background: ${theme.colors.grey2};
          border-top: 2px solid
            ${isPassing ? theme.colors.success50 : theme.colors.error50};
          .panel-content {
            padding: 20px;
            padding-top: 10px;
          }
          font-size: 1.2em;
        `
      )}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            li {
              padding: 10px;
              text-align: center;
              cursor: pointer;
              color: ${theme.colors.grey50};
              &:hover {
                color: ${theme.colors.grey100};
              }
              &.active {
                color: ${theme.colors.grey100};
              }
            }
          }
        `}
      >
        <ul>
          <li
            className={cx({ active: currTab === "feedback" })}
            onClick={() => setCurrTab("feedback")}
          >
            {isPassing && (
              <FontAwesomeIcon
                size="sm"
                color={theme.colors.success50}
                icon={faCheck}
              />
            )}{" "}
            Feedback
          </li>
          {hasOutputTab && (
            <li
              className={cx({ active: currTab === "output" })}
              onClick={() => setCurrTab("output")}
            >
              Output
            </li>
          )}
        </ul>
        <ul
          className={css`
            justify-content: flex-end;
          `}
        >
          {isPassing && (
            <li>
              {/* <PrimaryButton 
            onClick={ goToNextUnit }
            className={css`
              margin-right:20px;
            `}>
              Next <FontAwesomeIcon fontWeight={600} size="sm" icon={faChevronRight} />
            </PrimaryButton> */}
            </li>
          )}
        </ul>
      </div>
      {currTab === "feedback" && (
        <div className={cx("panel-content")}>
          {isPassing && (
            <h2
              className={css`
                margin-top: 0%;
              `}
            >
              <FontAwesomeIcon
                size="sm"
                color={theme.colors.success50}
                icon={faCheckCircle}
              />{" "}
              Complete!
            </h2>
          )}
          <ul
            className={css`
              padding: 0;
              margin: 0;
              li {
                list-style-type: none;
                padding: 0;
              }
            `}
          >
            {feedbackItems.map((item, i) => {
              return (
                <li key={i}>
                  <FontAwesomeIcon
                    size="sm"
                    color={colors(theme)[item.status]}
                    icon={item.icon}
                  />{" "}
                  {item.message}{" "}
                  {item.lineNumber && (
                    <span
                      className={css`
                        color: ${theme.colors.grey50};
                      `}
                    >
                      [line {item.lineNumber}
                      {item.columnNumber && `, col ${item.columnNumber}`}]
                      {item.errorCode && `: ${item.errorCode}`}
                    </span>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {hasOutputTab && currTab === "output" && (
        <div className={cx("panel-content")}>
          <pre
            className={css`
              font-size: 1.4em;
              margin-top: 0;
              ${!output ? `color: ${theme.colors.grey50};` : ""}
            `}
          >
            {output ? output : "[No Output]"}
          </pre>
        </div>
      )}
    </section>
  );
};
