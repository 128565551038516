import React, { useEffect, useMemo, useRef }  from 'react';
import Editor from '@monaco-editor/react';
import { FeedbackItem, Console } from './panels/console';
import { findLineNumber, useEditor } from '../../providers/editor.provider';
import { EditorWrap } from './editor-wrap';
import { editorCSS } from './editor.styles';
import { HintPanel } from './panels/hint-panel';
import { ErrorWithMarker } from './editor.types';
import { faFlag, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { truncate } from '../../utils/truncate';
import { TextPanel } from './panels/text-panel';
import { css, cx } from '@emotion/css';
import MarkdownView from 'react-showdown';
import { markdownStyles } from './panels/markdown-styles';
import { ExpectedTypeError } from '../../../shared/unit.types';
import { useSection } from '../../hooks/use-section';
import { QuizPanel } from './panels/quiz-panel';
import { useTheme } from '@emotion/react';
import { Breadcrumbs } from './panels/breadcrumbs';
import { CompilerOptions } from 'typescript';

export const TypescriptEditor = () => {
  const { 
    unit,
    code,
    session,
    updateCurrentSession,
    opacity,
    loading, 
    editorRef,
    reset,

    monacoRef,
    onMount,
    onChange,
    onValidate,
    lastValidatedAt,
    mountedAt,

    markers,
    setMarkers,
  } = useEditor();
  const theme = useTheme();

  const ignoredMarkers = ["80006"];

  const expectedTypeErrorsRef = useRef<string[]>([]);
  const errorsWithLineNumbers = useMemo( () => {
    const editorValue = editorRef?.current?.getValue();
    if(!editorValue || !code){
      return [];
    }
    return (unit?.expectedTypeErrors ?? []).map<ExpectedTypeError>( error =>{
      const line = findLineNumber( editorValue, error.lineContent )
      return { ...error, line }
    })
  }, [session?.value, unit?.expectedTypeErrors]);

  const unexpectedMarkers = useMemo( () => {
    return markers.filter( marker => 
      (typeof marker.code === "string" && !ignoredMarkers.includes(marker.code)) && 
      !errorsWithLineNumbers.find( error => 
        error.line === marker.startLineNumber  ||
        error.line === marker.endLineNumber
      )
    )
  }, [markers]);

  const expectedErrors = useMemo( () => {
    return errorsWithLineNumbers.map<ErrorWithMarker>( error => {
      const matchingMarker = markers.find( marker => {
        const isLineMatch = marker.startLineNumber === error.line ||
        marker.endLineNumber === error.line;
        return isLineMatch
      })
      return {
        ...error,
        matchingMarker: matchingMarker,
      };
    }) ?? []
  }, [errorsWithLineNumbers, markers]);

  const [presentErrors, absentErrors] = useMemo( () => 
    [
      expectedErrors.filter( error => !!error.matchingMarker ),
      expectedErrors.filter( error => !error.matchingMarker ),
    ]
  , [expectedErrors]);

  const onMountWrap: typeof onMount =( editor, monaco ) => {
    onMount( editor, monaco )
  }

  /** It doesn't always validate on mount, so this resets any stale markers */
  useEffect( () => {
    setMarkers([]);
  }, [unit]);

  useEffect( () => {
    if(!monacoRef?.current){
      return;
    }
    
    const monaco = monacoRef?.current;
    console.log({expectedErrors})
    const decorations = expectedErrors.filter( error => !!error.line)
      .map( error => ({
        range: new monaco.Range(
          error.line as number, Number.NEGATIVE_INFINITY,
          error.line as number, Number.POSITIVE_INFINITY
        ), options: { 
          isWholeLine: true, 
          linesDecorationsClassName: error.matchingMarker 
            ? 'line-icon line-flag success' 
            : 'line-icon line-flag error'
        },
      }))
    console.log({decorations})


    //remove old decorations
    const prevDecorations = editorRef?.current?.deltaDecorations(expectedTypeErrorsRef.current ?? [], []);
    //assign new decorations, and assign them to ref
    expectedTypeErrorsRef.current = editorRef?.current?.deltaDecorations(prevDecorations ?? [], decorations) ?? [];
  }, [monacoRef.current, expectedErrors])


  const outputItems = [
    ...unexpectedMarkers.map<FeedbackItem>( err => ({
      lineNumber: err.startLineNumber,
      columnNumber: err.startColumn,
      icon: faTimesCircle,
      message: err.message,
      status: 'error',
    })),
    ...presentErrors.map<FeedbackItem>( err => ({
      lineNumber: err.line,
      columnNumber: err.character,
      icon: faFlag,
      message: <><strong>Successfully Found Error:</strong> {truncate( err.renderedMessage, 80, "...")}</>,
      status: 'success',
    })),
    ...absentErrors.map<FeedbackItem>( err => ({
      lineNumber: err.line,
      columnNumber: err.character,
      icon: faFlag,
      message: <><strong>Missing Expected Error:</strong> {truncate(err.renderedMessage, 80, "...")}</>,
      status: 'error',
    })),
  ]



  const isPassing = !unexpectedMarkers.length 
    && !absentErrors.length
    && !loading
    && !!session?.value
    && !!lastValidatedAt;

  
    

  console.log({isPassing, unexpectedMarkers, absentErrors, loading, session});

  useEffect( () => {
    updateCurrentSession({
      completed_at: isPassing ? new Date() : undefined,
    })
  }, [isPassing])

  if(loading){
    return null
  }

  return <div className={css`width: 100%; display: flex;`}>

      <EditorWrap opacity={opacity}>
        <div className={css`display:flex; height: 60%; background: ${theme.colors.grey5};`}>
          <TextPanel >
            <div>
              {unit?.section?.type === "assessment" && 
                <QuizPanel unit={unit} section={unit.section} />
              }
              {unit?.section?.type === "tutorial" && 
                <Breadcrumbs />
              }
              <MarkdownView  markdown={unit?.description ?? ''} 
                flavor="github" 
                className={cx(markdownStyles(theme))}
              />
            </div>
            <HintPanel hints={unit?.hints ?? []} finishMessage={unit?.finishMessage} isPassing={isPassing} />
          </TextPanel>
          <div className={css`flex-basis: 70%; height: 100%;`}>
            <Editor {...{
              className: editorCSS(theme),
              height: '100%',
              defaultLanguage: unit?.language === "javascript" ? "typescript" : unit?.language,
              defaultValue: code?.workingCode,
              theme:  "my-dark",
              onMount: onMountWrap,
              onChange: onChange,
              onValidate: onValidate,
              beforeMount: (edtr) => {
                const opts = edtr.languages.typescript.typescriptDefaults.getCompilerOptions();
                const tsConfig: typeof opts = {strict: true};
                edtr.languages.typescript.typescriptDefaults.setCompilerOptions({...opts, ...tsConfig})
              },
              options: {
                fontSize: 14,
                readOnly: false,
                minimap: {
                  enabled: false,
                },
              }
            }}
            />
          </div>
        </div>
      <Console 
        {...{isPassing,
          feedbackItems: outputItems,
          output: null
        }}
      />
    </EditorWrap>
  </div>
}