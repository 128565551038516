import { css } from "@emotion/css";
import { useTheme } from "@emotion/react";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { SideBar } from "../components/nav/sidebar-nav";
import { useStyleInjector } from "../hooks/useStyleInjector";

export const InfoRoute = (_props: RouteComponentProps) => {
  const theme = useTheme();

  useStyleInjector`
  body {
      background: linear-gradient(270deg, #000000, #484d4c);
      background-size: 400% 400%;

      -webkit-animation: GradientAnimation 15s ease infinite;
      -moz-animation: GradientAnimation 15s ease infinite;
      animation: GradientAnimation 15s ease infinite;
  }

  @-webkit-keyframes GradientAnimation {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @-moz-keyframes GradientAnimation {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @keyframes GradientAnimation {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }`;

  return (
    <>
      <SideBar
        currPage="info"
        className={css`
          background: transparent;
        `}
      />
      <main
        className={css`
          width: 100%;
          padding: 20px;
          padding-top: 0px;
        `}
      >
        <article
          className={css`
            font-family: charter, Georgia, Cambria, "Times New Roman", Times,
              serif;
            max-width: 300px;
            padding: 0px 15px;
            color: ${theme.colors.grey100};
            button {
              cursor: pointer;
            }
          `}
        >
          <h2>Welcome to codemastery.dev</h2>
          <p>
            Fly through our courses to master your skills in a fast and sticky
            way. These small, fast-paced modules should give you just enough
            exposure to a concept to remember it for the real world.{" "}
          </p>
          <br />
          <Link
            className={css`
              background: #eee;
              padding: 10px 20px;
              border-radius: 5px;
              text-decoration: none;
              background-color: ${theme.colors.tsBlue};
              color: ${theme.colors.grey100};
              &:hover {
                background: ${theme.colors.tsBlueAlt};
              }
            `}
            to={`/ts/interactive-handbook/intro/0`}
          >
            Go to Typescript
          </Link>
        </article>
      </main>
    </>
  );
};
