import { injectGlobal } from "@emotion/css";
import { serializeStyles } from "@emotion/serialize";
import { StyleSheet } from "@emotion/sheet";
import { useEffect } from "react";
import { serialize, compile, middleware, rulesheet, stringify } from "stylis";

function customInjectGlobal(...args: Parameters<typeof injectGlobal>) {
  //@ts-ignore
  const { name, styles } = serializeStyles(...args);
  const sheet = new StyleSheet({
    key: `global-${name}`,
    container: document.head
  });
  const stylis = (styles) =>
    serialize(
      compile(styles),
      middleware([
        stringify,
        rulesheet((rule) => {
          sheet.insert(rule);
        })
      ])
    );
  stylis(styles);
  return () => sheet.flush();
}

export const useStyleInjector = (...args) => {
  useEffect( () => customInjectGlobal(...args), []);
}