import { css } from "@emotion/css";
import { Theme } from "../../providers/ui.provider";

export const editorCSS = (theme:Theme) =>  css`
/* .view-lines, .presentation, .contentWidgets { z-index:3; } */
/* .view-overlays { z-index: 1; } */
.lines-content { padding-left:5px; }
.idoc-readonly-line {
  background: ${theme.colors.grey20};
  border-radius: 3px;
  box-sizing: content-box;
  border-right: 4px solid ${theme.colors.grey20};
  border-left: 4px solid ${theme.colors.grey20};
  transform: translateX(-4px);
}
.idoc-readonly-mark {
  /* width:100% !important; */
  background: ${theme.colors.grey20};
  border-radius: 3px;
  box-sizing: content-box;
  border-right: 4px solid ${theme.colors.grey20};
  border-left: 4px solid ${theme.colors.grey20};
  /* transform: translateX(-4px); */
}
.editable-range {
  /* background: #1e1e1e;
  z-index: 2;
  border-radius: 5px; */
  /* border: 3px solid ${theme.colors.grey20}; */
  /* border-left: 2px solid #0db1fe;
  border-right: 2px solid #0db1fe; */
  /* border-bottom: 1px dashed #2e2e2e;
  background: black;
  border-radius: 4px; */
}
.line-icon {
  &:before {
    margin-left: 7px;
    font-weight: bold;
    margin-right: 5px;
    font-size:1.5em;
    position: relative; 
    top:-2px;
  }
  &.error:before {
    color: ${theme.colors.error50};
  }
  &.success:before {
    color: ${theme.colors.success50};
  }
  &.warning:before {
    color: ${theme.colors.warning50};
  }
}
.line-flag:before {
  content: '\\2691';
}
.line-check:before {
  content: '\\2714';
}
.line-times:before {
  content: '\\2716';
}
.line-circle-dotted:before {
  content: '\\25CC';
}
`;