import { css } from "@emotion/css";
import { Theme } from "../../../providers/ui.provider";

export const markdownStyles = (theme: Theme) => css` 
  font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
  text-align:left;
  a {
    color: ${theme.colors.blue50};
    text-decoration: none;
    &:hover {
      color: ${theme.colors.blue20};
    }
  }
  code {
    border-radius: 4px;
    padding: 4px;
    font-family: 'Courier New', Courier, monospace;
    font-weight:bold;
    color: ${theme.colors.pink};
  }
`