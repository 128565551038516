import { css, cx } from "@emotion/css";
import { useState, useMemo, useEffect } from "react";
import MarkdownView from "react-showdown";
import { useEditor } from "../../../providers/editor.provider";
import { useNavigation } from "../../../providers/navigation.provider";
import { markdownStyles } from "./markdown-styles";
import { useTheme } from "@emotion/react";
import { PrimaryButton } from "../../button/primary-button";
import { SecondaryButton } from "../../button/secondary-button";
import { useSession } from "../../../providers/session.provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

type Props = {
  hints: string[];
  finishMessage: string | undefined;
  isPassing: boolean;
};
export const HintPanel = ({ hints, finishMessage, isPassing }: Props) => {
  const { unit, showAnswer, reset } = useEditor();
  const { attachSessions } = useSession();
  const { goToNextUnit, goToNextSection } = useNavigation();
  const [hintsShowing, setHintsShowing] = useState<number[]>([]);
  const [hasShownAnswer, setHasShownAnswer] = useState(false);
  const theme = useTheme();

  const showHint = (i: number) => {
    const found = hintsShowing.includes(i);
    return found ? undefined : setHintsShowing([...hintsShowing, i]);
  };

  const hideHint = (i: number) => {
    setHintsShowing(hintsShowing.filter((hint, n) => n !== i));
  };

  const onShowAnswer = () => {
    setHasShownAnswer(true);
    showAnswer();
  };

  const seeQuizResults = () => {
    const unitsWithSessions = attachSessions(unit?.section.units ?? []);
  };

  const hintsWithShowing = useMemo(
    () =>
      hints.map((hint, i) => ({
        text: hint,
        showing: hintsShowing.includes(i),
      })),
    [hints, hintsShowing]
  );

  const allHintsShowing = hintsWithShowing.every((hint) => hint.showing);

  useEffect(() => {
    if (!isPassing) {
      setHasShownAnswer(false);
    }
  }, [isPassing]);

  const unitIndex = useMemo(
    () => unit?.section.units.findIndex((u) => unit.id === u.id),
    [unit]
  );
  const isQuiz = unit?.section?.type === "assessment";
  const isQuizIntro = isQuiz && unitIndex === 0;
  const isLastUnitInSection =
    unit && unit?.section.units.length - 1 === unitIndex;

  const showShowAnswerBtn =
    (allHintsShowing || isPassing) && !hasShownAnswer && !isQuiz;

  const showDone = isPassing && !isQuizIntro;
  const showNextButton = isPassing;
  const showFinishMessage = isPassing && !!finishMessage;
  const showSkipSectionButton = isQuizIntro;
  const skipSectionButtonText = isQuiz ? "Skip This Quiz" : "Skip This Section";
  const nextButtonText = isQuizIntro ? "Begin!" : "Next Question!";

  return (
    <>
      <div>
        {hintsWithShowing.map((hint, i) => {
          const prevIsShowing = !!hintsWithShowing[i - 1]?.showing;
          return hint.showing ? (
            <>
              <hr
                className={css`
                  border: none;
                  border-bottom: 2px dotted ${theme.colors.grey50};
                `}
              />
              <MarkdownView
                flavor="github"
                className={cx(markdownStyles(theme))}
                markdown={hint.text}
              />
            </>
          ) : prevIsShowing || i === 0 ? (
            <SecondaryButton onClick={() => showHint(i)}>
              Show {i === 0 ? "a" : "another"} Hint
            </SecondaryButton>
          ) : null;
        })}
        {showShowAnswerBtn && (
          <SecondaryButton onClick={onShowAnswer}>Show Answer</SecondaryButton>
        )}
      </div>
      {isPassing && (
        <div
          className={css`
            background: ${theme.colors.success50};
            position: relative;
            padding-top: 1px;
            margin-top: 30px;
            min-height: 275px;
            &::before {
              position: absolute;
              width: 100%;
              height: 100%;
              content: "";
              background-color: inherit;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: -1;
              transform-origin: top left;
              transform: skewY(-5deg);
            }
          `}
        >
          {showDone && (
            <h1
              className={css`
                color: white;
                text-shadow: 2px 2px 1px rgb(0 0 0 / 40%);
              `}
            >
              <FontAwesomeIcon icon={faCheckCircle} /> Done!
            </h1>
          )}
          {showFinishMessage && (
            <>
              <MarkdownView
                flavor="github"
                className={cx(
                  markdownStyles(theme),
                  css`
                    color: ${theme.colors.grey100};
                    code {
                      color: ${theme.colors.grey0};
                    }
                  `
                )}
                markdown={finishMessage}
              />
            </>
          )}
          {isQuiz && isLastUnitInSection && isPassing && (
            <PrimaryButton onClick={seeQuizResults}>
              See Quiz Results
            </PrimaryButton>
          )}
          {showNextButton && (
            <PrimaryButton color="white" onClick={goToNextUnit}>
              {nextButtonText}
            </PrimaryButton>
          )}
          {showSkipSectionButton && (
            <SecondaryButton onClick={goToNextSection}>
              {skipSectionButtonText}
            </SecondaryButton>
          )}
        </div>
      )}
    </>
  );
};
