import React from "react"
import {useTheme} from '@emotion/react'
import { css, cx } from "@emotion/css";

type Props = React.ComponentProps<'button'> & {
  color?: "white" | "green";
}


export const PrimaryButton = React.forwardRef<
  HTMLButtonElement, Props>
(({children, color="green", ...props}, ref) => {
  const theme = useTheme();
  return <button {...props}
    ref={ref}
    className={cx(css`
      color: ${{
        green: theme.colors.grey100,
        white: theme.colors.grey2,
      }[color]};
      background: ${{
        green: theme.colors.success50,
        white: theme.colors.grey100,
      }[color]};
      border-bottom: 1px solid ${{
        green: theme.colors.success20,
        white: theme.colors.grey0,
      }[color]};
      cursor: pointer;
      border-radius: 3px;
      font-weight: bold;
      padding: 10px 15px;
      border: none;
      margin-right: 10px;
      margin-bottom: 10px;
      box-shadow: 0px 0px 10px rgb(0,0,0,0.2);
      transition: background 0.2s;
      &:hover { 
        background: ${{
          green: theme.colors.success20,
          white: theme.colors.grey90,
        }[color]};
        box-shadow: 0px 0px 10px rgb(0,0,0,0.1)
      }
    `,props.className)
  }>
    {children}
  </button>
})