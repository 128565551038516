import { useState, createContext, useEffect } from "react";
import { ThemeProvider } from "@emotion/react";
import { injectGlobal } from "@emotion/css";

export const theme = {
  colors: {
    grey0: "#000",
    grey2: "#181818",
    grey5: "#1e1e1e",
    grey10: "#252526",
    grey15: "#2a2d2e",
    grey20: "#2f2f2f",
    grey25: "#333",
    grey30: "#37373d",
    grey35: "#444",
    grey40: "#666",
    grey50: "#aaa",
    grey70: "#ccc",
    grey80: "#d2d7dd",
    grey90: "#dde1e7",
    grey95: "#eee",
    grey100: "#fff",

    error50: "#a70505",

    success10: "#2d5218",
    success20: "#147651",
    success50: "#3cc792",
    success60: "#4ec9b0",

    warning50: "#dab349",

    blue20: "#1786a8",
    blue50: "#519aba",

    pink: "#9f3391",

    tsBlueAlt: "#519aba",
    tsBlue: "#0fb2ff",
    jsYellow: "#baaf51",
    jsYellowAlt: "#EFD81D",
    goBlue: "#59cae3",
  },
};

export type Theme = typeof theme;

type UIContextValue = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};
export const UIContext = createContext<UIContextValue>(
  undefined as unknown as UIContextValue
);

type Props = {
  children: React.ReactNode;
};
export const UIProvider = ({ children }: Props) => {
  const [currTheme, setCurrTheme] = useState<Theme>(theme);

  const value: UIContextValue = {
    theme: currTheme,
    setTheme: setCurrTheme,
  };

  useEffect(() => {
    injectGlobal`
      * { box-sizing: border-box; }
      body {
        color: ${theme.colors.grey100};
        background: ${theme.colors.grey10};
        font-family: Wotfard,Futura,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
        /* font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif; */
      }
      .no-scroll-bars {
        overflow-y: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        *::-webkit-scrollbar { /* WebKit */
          width: 0;
          height: 0;
        }
      }
      button {
        font-family: Wotfard,Futura,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
      }
      @font-face {
        font-family: 'Patrick Hand SC';
        font-style: normal;
        font-weight: 400;
        src: local('Patrick Hand SC'),
          local('PatrickHandSC-Regular'),
          url('https://fonts.gstatic.com/s/patrickhandsc/v4/OYFWCgfCR-7uHIovjUZXsZ71Uis0Qeb9Gqo8IZV7ckE.woff2')
            format('woff2');
        unicode-range: U+0100-024f, U+1-1eff,
          U+20a0-20ab, U+20ad-20cf, U+2c60-2c7f,
          U+A720-A7FF;
      }
      .svg-inline--fa {
        vertical-align: 0 !important;
      }
      #root { 
        display: flex;
      }
    `;
  }, [currTheme]);

  return (
    <UIContext.Provider value={value}>
      <ThemeProvider theme={currTheme}>{children}</ThemeProvider>
    </UIContext.Provider>
  );
};
