import { css, cx } from "@emotion/css";
import React, { Children, useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";

type Props = {
  children: React.ReactNode;
};
export const TextPanel = ({ children }: Props) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const [mountedAt, setMountedAt] = useState(new Date());
  useEffect(() => {
    //If the children change after 5 seconds of being mounted,
    //scroll to the bottom of the element
    if (ref.current && new Date().valueOf() - mountedAt.valueOf() > 5000) {
      ref.current.scrollTop =
        ref.current.scrollHeight - ref.current.clientHeight;
    }
  }, [children]);

  return (
    <div
      ref={ref}
      className={cx(
        "no-scroll-bars",
        css`
          max-width: 300px;
          flex-basis: 30%;
          box-sizing: border-box;
          color: ${theme.colors.grey10};
          font-size: 1.2em;
          background: ${theme.colors.grey90};
          margin: 10px;
          margin-right: 0;
          z-index: 9;
          border-radius: 10px;
          box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.4);
          overflow: scroll;
          > div {
            padding-left: 15px;
            padding-right: 15px;
            &:first-of-type {
              padding-top: 15px;
            }
            &:last-of-type {
              padding-bottom: 15px;
            }
          }
        `
      )}
    >
      {children}
    </div>
  );
};
