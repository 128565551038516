import { createContext, useContext, useMemo } from "react";
import {
  UnitWithId,
  ContextualizedUnit,
  ContextualizedUnitCourse,
  ContextualizedUnitEcosystem,
  ContextualizedUnitSection,
  UnitEcosystem,
} from "../../shared/unit.types";
import data from "../units.json";

const ecosystems = data as unknown as UnitEcosystem[];

type ContentContextValue = {
  ecosystems: ContextualizedUnitEcosystem[];
};
const ContentContext = createContext<ContentContextValue>({
  ecosystems: [],
});

type Props = {
  children: React.ReactNode;
};
export const ContentProvider = ({ children }: Props) => {
  // const contextualizedEcosystems = ecosystems.map<ContextualizedUnitEcosystem>( ecosystem => ({
  //   ...ecosystem,
  //   courses: ecosystem.courses.map<ContextualizedUnitCourse>( course => ({
  //     ...course as ContextualizedUnitCourse,
  //     ecosystem: ecosystem as ContextualizedUnitEcosystem,
  //     sections: course.sections.map<ContextualizedUnitSection>( section => ({
  //       ...section as ContextualizedUnitSection,
  //       course: course as ContextualizedUnitCourse,
  //       units: section.units.map<ContextualizedUnit>( unit => ({
  //         ...unit as ContextualizedUnit,
  //         section: section as ContextualizedUnitSection,
  //       }))
  //     }))
  //   }))
  // }))

  const contextualizedEcosystems = ecosystems.map((ecosystem) => {
    const cxtEcosystem: ContextualizedUnitEcosystem = {
      ...ecosystem,
      courses: [],
    };
    cxtEcosystem.courses = ecosystem.courses.map((course) => {
      const cxtCourse: ContextualizedUnitCourse = {
        ...course,
        ecosystem,
        sections: [],
      };
      cxtCourse.sections = course.sections.map((section) => {
        const cxtSection: ContextualizedUnitSection = {
          ...section,
          course: cxtCourse,
          units: [],
        };
        cxtSection.units = section.units.map<ContextualizedUnit>((unit) => {
          //The unit is given an id by the publishing script. But we don't tell typescript that anywhere
          const cxtUnit: ContextualizedUnit = {
            id: (unit as UnitWithId).id,
            ...unit,
            section: cxtSection,
          };
          return cxtUnit;
        });
        return cxtSection;
      });
      return cxtCourse;
    });
    return cxtEcosystem;
  });

  console.log({ contextualizedEcosystems });

  const value: ContentContextValue = useMemo(
    () => ({
      ecosystems: contextualizedEcosystems,
    }),
    [contextualizedEcosystems]
  );

  return (
    <ContentContext.Provider value={value}>{children}</ContentContext.Provider>
  );
};

export const useContent = () => useContext(ContentContext);
