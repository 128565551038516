import React, { useEffect, useMemo, useRef, useState }  from 'react';
import Editor from '@monaco-editor/react';
import axios from 'axios'
import { RunResponseDTO } from '../../../shared/api.dto'
import { FeedbackItem, Console } from './panels/console';
import { useEditor } from '../../providers/editor.provider';
import { EditorWrap } from './editor-wrap';
import { editorCSS } from './editor.styles';
import { faCheckCircle, faPlayCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Assertion } from '../../providers/unit.provider'
import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';

export const isFailedRunResponse = (res: RunResponseDTO) => {
  return !!res.errors?.length
}

const getFullAssertionText = (startingLine: number, allLines: string[]) => {
  let openParens = 0;
  let done = false;
  const matchingLines = allLines.slice(startingLine-1).filter( line => {
    if(done){
      return false;
    }
    openParens += line.split("").filter( char => char === "(" ).length
    openParens -= line.split("").filter( char => char === ")" ).length
    if( openParens <= 0 ){
      done = true;
    }
    return true;
  })
  return matchingLines.join("\n")
}

const humanReadableAssertion = (code: string) => {
  return code.replace("test( i => i.", "")
    .replaceAll(".", " ")
    .replaceAll("(", " ")
    .replaceAll(")", " ")
    .replaceAll(";", "")
    .replaceAll("\n", " ");
}


export const JavascriptEditor = () => {
  const { 
    unit,
    code,
    session,
    updateCurrentSession,
    opacity,
    loading, 
    editorRef,
    monacoRef,
    reset,
    markers,
    setMarkers,

    onMount,
    onChange,
    onValidate,
    lastValidatedAt,
    setLastValidatedAt
  } = useEditor();

  const [running, setRunning] = useState(false)
  const [lastRunResponse, setLastRunResponse] = useState<RunResponseDTO>();
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const assertionDecorationsRef = useRef<string[]>([]);
  const theme = useTheme();

  const run = () => {
    if(!unit){
      return;
    }
    if(running){
      return;
    }
    if(timeoutRef.current){
      clearTimeout( timeoutRef.current )
    }
    timeoutRef.current = setTimeout( () => {
      setRunning(true);
      axios.post('/api/run-node', {
        language: unit?.language, 
        code: editorRef.current?.getValue(),
      } ).then( res => {
        setLastRunResponse(res.data);
      } )
      .finally( () => {
        setRunning(false);
        //If it hasn't validated by this point, 
        //it probably won't. It's probably all comments
        if(!lastValidatedAt){
          setLastValidatedAt(new Date())
        }
      })
    }, 100)

  }

  const onChangeWrap: typeof onChange = (value, evt) => {
    setLastRunResponse(undefined);
    onChange(value, evt)
  }

  const onValidateWrap: typeof onValidate = (markers) => {
    setLastRunResponse(undefined);
    onValidate(markers)
  }

  const onMountWrap: typeof onMount = (editor, monaco) => {
    monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
      ...monaco.languages.typescript.typescriptDefaults.getCompilerOptions(),
      moduleResolution: monaco.languages.typescript.ModuleResolutionKind.NodeJs,
      allowSyntheticDefaultImports: true,
    });

    (async function(){
      monaco.languages.typescript.typescriptDefaults.addExtraLib( 
        await (await fetch( 'https://unpkg.com/@types/chai@latest/index.d.ts' )).text(),
        'file:///node_modules/@types/chai/index.d.ts'
      )
      monaco.languages.typescript.typescriptDefaults.addExtraLib( 
        `const test = (assertionFn: (i: typeof chai ) => void) => void`
      )
    }())

    onMount(editor, monaco)
  }
  
  useEffect( () => {
    if(!markers && lastValidatedAt){
      return run();
    }

    //This is a dirty way of getting latest marker state after a timeout
    //This is to prevent immediately running before the editor has a chance
    //to validate and set the markers, which happens on first page load
    setTimeout( () => {
      setMarkers( (prev) => {
        if(!prev.length){
          run();
        }
        return prev
      })
    }, 200)
  }, [markers, session?.value])

  const assertions = useMemo( () => {
    const lines = session?.value.split("\n") ?? [];
    return lines.reduce<Assertion[]>( (acc, line, i) => {
      return /test\(.*=>.*expect\(.*\)/g.test(line)
        ? [...acc, {lineNumber: i+1, lineContent: getFullAssertionText(i+1, lines)}]
        : acc;
    }, [])
    
  }, [session?.value])

  useEffect( () => {
    if(!monacoRef?.current){
      return;
    }
    
    const hasRun = !!lastRunResponse;
    const lastRunErrors = lastRunResponse?.errors ?? [];
    const failedAssertions = lastRunResponse?.result?.failedAssertions ?? [];

    const monaco = monacoRef?.current;
    const decorations = assertions.map( error => {
      let className;
      const match = failedAssertions.find( a => a.startLineNumber === error.lineNumber);
      if( !hasRun || !lastValidatedAt ){
        className = 'line-icon line-circle-dotted warning';
      } else if( hasRun && !lastRunErrors?.length && !match ) {
        className = 'line-icon line-check success';
      } else {
        className = 'line-icon line-times error';
      }
      return {
        range: new monaco.Range(
          error.lineNumber, Number.NEGATIVE_INFINITY,
          error.lineNumber as number, Number.POSITIVE_INFINITY
        ), options: { 
          isWholeLine: true, 
          linesDecorationsClassName: className
        },
      }})


    //remove old decorations
    const prevDecorations = editorRef?.current?.deltaDecorations(assertionDecorationsRef.current ?? [], []);
    //assign new decorations, and assign them to ref
    assertionDecorationsRef.current = editorRef?.current?.deltaDecorations(prevDecorations ?? [], decorations) ?? [];
  }, [assertions, lastRunResponse])


  const isPassing = !!(
    !!session?.value 
    && !loading
    && lastRunResponse
    && !isFailedRunResponse(lastRunResponse)
    && !lastRunResponse.result?.failedAssertions.length
    && !markers.length
    && lastValidatedAt
  );

  useEffect( () => {
    if( session?.completed_at && !isPassing){
      updateCurrentSession({
        completed_at: undefined,
      })
    } else if( !session?.completed_at && isPassing){
      updateCurrentSession({
        completed_at: new Date(),
      })
    }
  }, [isPassing])

  // show the validation errors always.
  // show the last run errors if:
   // last run response isnt empty
  // show the failed assertions if :
     //last run response is not empty, 
  // show the assertion decorations if:
    // yellow open, if !last run result
    // x if last run result and found in result

  const outputItems: FeedbackItem[] = useMemo( () => {
    const hasRun = !!lastRunResponse;
    const lastRunErrors = lastRunResponse?.errors ?? [];
    const failedAssertions = lastRunResponse?.result?.failedAssertions ?? [];
    const lines = editorRef.current?.getValue()?.split("\n") ?? [];
    const parsedAssertions = failedAssertions.map( asser => {
      const content = lines.slice(asser.startLineNumber+1, asser.endLineNumber+1).join("\n")
      return {...asser, display: humanReadableAssertion(content)}
    })

    const feedbackItems: FeedbackItem[] = [];
    if(running){
      feedbackItems.push({
        message: "Code is running...",
        icon: faPlayCircle,
        status: 'warning',
      })
    }
    feedbackItems.push(...[
      ...markers.map<FeedbackItem>( err => ({
        lineNumber: err.startLineNumber,
        columnNumber: err.startColumn,
        icon: faTimesCircle,
        message: err.message,
        status: 'error',
      })),
      ...lastRunErrors.map<FeedbackItem>( err => ({
        message: <><strong>Error</strong> {err.message}</>,
        icon: faTimesCircle,
        status: 'error',
        lineNumber: err.line === 0 ? undefined : err.line
      })),
      ...(!hasRun || lastRunErrors.length) ? [] : assertions.map<FeedbackItem>( assertion => {
        const match = parsedAssertions.find( a => a.startLineNumber === assertion.lineNumber)
        return {
          icon: match ? faTimesCircle : faCheckCircle,
          status: match ? 'error' : 'success',
          lineNumber: assertion.lineNumber,
          message: match 
            ? <><strong>Failed Assertion</strong> {match.message}</>
            : <><strong>Assertion Passed!</strong> {humanReadableAssertion(assertion.lineContent)}</>,
        }
      })
    ])
    return feedbackItems;
  }, [markers, assertions, lastRunResponse, running]);

  if(loading){
    return null
  }

  return <div className={css`width: 100%; display: flex;`}>

      <EditorWrap opacity={opacity}>
        <div className={css`display:flex; height: 60%; background: ${theme.colors.grey5};`}>
          
          <div className={css`flex-basis: 70%; height: 100%;`}>
            <Editor {...{
              className: editorCSS(theme),
              height: '100%',
              defaultLanguage: unit?.language === "javascript" ? "typescript" : unit?.language,
              defaultValue: code?.workingCode,
              theme:  "my-dark",
              onMount: onMountWrap,
              onChange: onChangeWrap,
              onValidate: onValidateWrap,
              options: {
                fontSize: 14,
                readOnly: false,
                minimap: {
                  enabled: false,
                },
              }
            }}
            />
          </div>
        </div>
      <Console 
        {...{isPassing,
          feedbackItems: outputItems,
          output: lastRunResponse?.result?.response ?? null
        }}
      />
    </EditorWrap>
  </div>
}