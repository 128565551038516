import { createContext, useContext, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import {
  ContextualizedUnitEcosystem,
  ContextualizedUnitCourse,
  ContextualizedUnitSection,
  ContextualizedUnit,
} from "../../shared/unit.types";
import { useContent } from "./content.provider";

type NavigationContextValue = {
  content: ContextualizedUnitEcosystem[];
  ecosystem: ContextualizedUnitEcosystem | undefined;
  course: ContextualizedUnitCourse | undefined;
  section: ContextualizedUnitSection | undefined;
  unit: ContextualizedUnit | undefined;
  goToUnit: (slug: number) => void;
  goToSection: (slug: string) => void;
  goToCourse: (slug: string) => void;
  goToEcosystem: (slug: string) => void;
  goToNextUnit: () => void;
  goToNextSection: () => void;
  goToNextCourse: () => void;
  goToNextEcosystem: () => void;
  getPath: (
    ecosystem: string,
    course?: string,
    section?: string,
    unit?: string | number
  ) => string;
};

const NavigationContext = createContext<NavigationContextValue>(
  undefined as unknown as NavigationContextValue
);

type Props = {
  children: React.ReactNode;
};
export const NavigationProvder = ({ children }: Props) => {
  const { ecosystems } = useContent();
  const history = useHistory();
  const location = useLocation();

  const [, ecosystemSlug, courseSlug, sectionSlug, unitSlug] =
    location.pathname.split("/");

  const { currEcosystem, currCourse, currSection, currUnit } = useMemo(() => {
    const currEcosystem =
      ecosystems.find((e) => e.slug === ecosystemSlug) ?? ecosystems[0];
    const currCourse =
      currEcosystem.courses.find((f) => f.slug === courseSlug) ??
      currEcosystem.courses[0];
    const currSection =
      currCourse.sections.find((s) => s.slug === sectionSlug) ??
      currCourse.sections[0];
    const currUnit =
      currSection.units.find((u) => u.id.toString() === unitSlug) ??
      currSection.units[0];
    return { currEcosystem, currCourse, currSection, currUnit };
  }, [ecosystemSlug, courseSlug, sectionSlug, unitSlug]);

  const goToEcosystem: NavigationContextValue["goToEcosystem"] = (slug) => {
    const ecosystem = ecosystems.find((e) => e.slug === slug);
    return ecosystem
      ? history.push(
          "/" +
            [
              ecosystem.slug,
              ecosystem.courses[0].slug,
              ecosystem.courses[0].sections[0].slug,
              ecosystem.courses[0].sections[0].units[0].id,
            ].join("/")
        )
      : undefined;
  };

  const goToCourse: NavigationContextValue["goToCourse"] = (slug) => {
    const course = currEcosystem.courses.find((f) => f.slug === slug);

    return course
      ? history.push(
          "/" +
            [
              currEcosystem.slug,
              course.slug,
              course.sections[0].slug,
              course.sections[0].units[0].id,
            ].join("/")
        )
      : undefined;
  };

  const goToSection: NavigationContextValue["goToSection"] = (slug) => {
    const section = currCourse.sections.find((s) => s.slug === slug);

    return section
      ? history.push(
          "/" +
            [
              currEcosystem.slug,
              currCourse.slug,
              section.slug,
              section.units[0].id,
            ].join("/")
        )
      : undefined;
  };

  const goToUnit: NavigationContextValue["goToUnit"] = (index) => {
    const unit = currSection.units[index];
    return unit
      ? history.push(
          "/" +
            [currEcosystem.slug, currCourse.slug, currSection.slug, index].join(
              "/"
            )
        )
      : undefined;
  };

  const goToNextEcosystem: NavigationContextValue["goToNextEcosystem"] = () => {
    const currEcosystemIndex = ecosystems.indexOf(currEcosystem);
    currEcosystemIndex + 1 === ecosystems.length
      ? goToEcosystem(ecosystems[0].slug) //go to the beginning
      : goToEcosystem(ecosystems[currEcosystemIndex + 1].slug);
  };

  const goToNextCourse: NavigationContextValue["goToNextCourse"] = () => {
    const currCourseIndex = currEcosystem.courses.indexOf(currCourse);
    currCourseIndex + 1 === currEcosystem.courses.length
      ? goToNextEcosystem()
      : goToCourse(currEcosystem.courses[currCourseIndex + 1].slug);
  };

  const goToNextSection: NavigationContextValue["goToNextSection"] = () => {
    const currSectionIndex = currCourse.sections.indexOf(currSection);
    currSectionIndex + 1 === currCourse.sections.length
      ? goToNextCourse()
      : goToSection(currCourse.sections[currSectionIndex + 1].slug);
  };

  const goToNextUnit: NavigationContextValue["goToNextUnit"] = () => {
    const index = currUnit.section.units.findIndex((u) => u.id === currUnit.id);
    index > -1 && index + 1 === currSection?.units.length
      ? goToNextSection()
      : goToUnit(index + 1);
  };

  const getPath: NavigationContextValue["getPath"] = (
    ecosystem,
    course,
    section,
    unit
  ) => "/" + [ecosystem, course, section, unit].filter((n) => !!n).join("/");

  useEffect(() => {
    if (
      currEcosystem?.slug !== ecosystemSlug ||
      currCourse?.slug !== courseSlug ||
      currSection?.slug !== sectionSlug ||
      currUnit?.id !== +unitSlug
    ) {
      history.push(
        "/" +
          [
            currEcosystem?.slug,
            currCourse?.slug,
            currSection?.slug,
            currUnit?.id ?? 0,
          ].join("/")
      );
    }
  }, [
    ecosystemSlug,
    courseSlug,
    sectionSlug,
    unitSlug,
    currEcosystem,
    currCourse,
    currSection,
    currUnit,
    history,
  ]);

  const value: NavigationContextValue = {
    content: ecosystems,
    ecosystem: currEcosystem,
    course: currCourse,
    section: currSection,
    unit: currUnit,
    goToNextUnit,
    goToNextSection,
    goToNextCourse,
    goToNextEcosystem,
    goToUnit,
    goToSection,
    goToCourse,
    goToEcosystem,
    getPath,
  };

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);
