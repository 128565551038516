import { css, cx } from "@emotion/css";
import { faInfoCircle, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { UnitEcosystem } from "../../../shared/unit.types";
import { useContent } from "../../providers/content.provider";
import { useTheme } from "@emotion/react";
import { Theme } from "../../providers/ui.provider";

export const sidebarNavItem = (theme: Theme) => css`
  padding: 10px;
  color: ${theme.colors.grey100};
  text-decoration: none;
  display: block;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-left: 3px solid transparent;
  opacity: 0.6;
  &.active {
    opacity: 1;
    border-left: 3px solid ${theme.colors.grey100};
    .icon {
      box-shadow: 0px 0px 10px;
    }
  }
  &:hover {
    opacity: 1;
  }
  .icon {
    display: block;
    box-sizing: border-box;
  }
`;

const userIcon = (theme: Theme) => css`
  ${sidebarNavItem(theme)}
  align-self: flex-end;
  font-size: 2em;
`;

const infoIcon = (theme: Theme) => css`
  ${sidebarNavItem(theme)}
  align-self: flex-end;
  font-size: 2em;
`;

type SideBarProps = {
  className?: string;
  currPage: string;
};

export const SideBar = ({ className, currPage }: SideBarProps) => {
  const { ecosystems } = useContent();
  const theme = useTheme();
  return (
    <nav
      className={cx(
        css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-top: 10px;
          background: ${theme.colors.grey25};
          height: 100vh;
        `,
        className
      )}
    >
      <div>
        {ecosystems.map((e, i) => {
          return (
            <EcosystemIcon
              key={i}
              ecosystem={e}
              isActive={currPage === e.slug}
            />
          );
        })}
      </div>
      <div>
        <Link
          className={cx(userIcon(theme), { active: currPage === "user" })}
          to="/user"
        >
          <FontAwesomeIcon icon={faUserAlt} />
        </Link>
        <Link
          className={cx(infoIcon(theme), { active: currPage === "info" })}
          to="/info"
        >
          <FontAwesomeIcon icon={faInfoCircle} inverse={true} />
        </Link>
      </div>
    </nav>
  );
};

const TSIcon = (theme: Theme) => css`
  ${sidebarNavItem(theme)}
  .icon {
    border: 2px solid ${theme.colors.tsBlue};
    font-size: 1em;
    font-weight: bold;
    color: ${theme.colors.tsBlue};
    padding-top: 13px;
    padding-left: 10px;
    padding-right: 3px;
    border-radius: 5px;
  }
`;

const JSIcon = (theme: Theme) => css`
  ${TSIcon(theme)}
  .icon {
    border: 2px solid ${theme.colors.jsYellowAlt};
    color: ${theme.colors.jsYellowAlt};
  }
`;

const GoIcon = (theme: Theme) => css`
  ${JSIcon(theme)}
  .icon {
    padding-left: 0;
    padding-right: 0;
    padding-top: 15px;
    border: 2px solid ${theme.colors.goBlue};
    color: ${theme.colors.goBlue};
  }
  img {
    width: 32px;
  }
`;

type Props = {
  ecosystem: UnitEcosystem;
  isActive: boolean;
};
export const EcosystemIcon = ({ ecosystem, isActive }: Props) => {
  const theme = useTheme();
  const href = `/${ecosystem.slug}`;
  switch (ecosystem.slug) {
    case "ts":
      return (
        <Link className={cx({ active: isActive }, TSIcon(theme))} to={href}>
          <span className="icon">TS</span>
        </Link>
      );
    case "js":
      return (
        <Link className={cx({ active: isActive }, JSIcon(theme))} to={href}>
          <span className="icon">JS</span>
        </Link>
      );
    case "go":
      return (
        <Link className={cx({ active: isActive }, GoIcon(theme))} to={href}>
          <span className="icon">
            <img src="/go-logo.png" alt="GoLang Logo" />
          </span>
        </Link>
      );
  }
  return null;
};
